import styled from '@emotion/styled'
import { WebNavLink } from '@news-mono/web-common'
import { colors } from '../__styling/settings/colors'
import { breakpoint } from '../__styling/style-functions'
import { calcRem } from '../__styling/style-functions/calc-rem'
import { transition } from '../__styling/style-mixins'

export const StyledLogoContainer = styled('div')(({ theme }) => ({
    padding: calcRem(theme.margins.md, theme.margins.md),
    margin: calcRem(-theme.margins.md, -theme.margins.md, theme.margins.lg),
    backgroundColor: colors.thewest.westblue,
    textAlign: 'center',

    svg: {
        width: '100%',
    },

    [breakpoint('sm')]: {
        paddingLeft: calcRem(theme.margins.lg),
        paddingRight: calcRem(theme.margins.lg),
        marginLeft: calcRem(-theme.margins.lg),
        marginRight: calcRem(-theme.margins.lg),
        textAlign: ['left', 'unset'],
        svg: {
            width: ['auto', 'unset'],
        },
    },

    [breakpoint('lg')]: {
        paddingLeft: calcRem(theme.margins.xl),
        paddingRight: calcRem(theme.margins.xl),
        marginLeft: calcRem(-theme.margins.xl),
        marginRight: calcRem(-theme.margins.xl),
    },
}))
StyledLogoContainer.displayName = 'StyledLogoContainer'

export const StyledRegionList = styled('ul')(({ theme }) => ({
    padding: 0,
    margin: 0,
    marginTop: calcRem(theme.margins.md),
    listStyle: 'none',
}))
StyledRegionList.displayName = 'StyledRegionList'

export const StyledRegionalItem = styled('li')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: calcRem(theme.margins.md, 0),
    marginBottom: calcRem(theme.margins.md),

    '&:not(:last-child)': {
        borderBottom: `1px solid ${colors.thewest.greyDolphin}`,
    },
}))
StyledRegionalItem.displayName = 'StyledRegionalItem'

export const StyledRegionalLogo = styled('img')({
    width: '100%',
    height: 'auto',
    maxWidth: 190,
    marginRight: 'auto',
})
StyledRegionalLogo.displayName = 'StyledRegionalLogo'

export const StyledWebLink = styled(WebNavLink)(({ theme }) => ({
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    padding: calcRem(4, theme.margins.sm),
    backgroundColor: colors.thewest.westblue,
    borderRadius: 2,
    color: colors.white,
    fontFamily: theme.fonts.sansSerif,
    fontSize: calcRem(14),
    outline: 'none',
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'uppercase',
    ...transition({ properties: ['background-color', 'color'] }),

    '&:not(:first-child)': {
        marginLeft: calcRem(theme.margins.md),
    },

    '&:hover': {
        backgroundColor: colors.thewest.greyElephant,
    },

    '&:focus': {
        backgroundColor: colors.thewest.greyElephant,
        textDecoration: 'underline',
    },
}))
StyledWebLink.displayName = 'StyledwebLink'

export const StyledLogoNavLink = styled(WebNavLink)({
    marginRight: 'auto',
})
StyledLogoNavLink.displayName = 'StyledLogoNavLink'
