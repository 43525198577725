import { TheWestSection } from '@news-mono/common'
import { AppState, MetaState } from '@news-mono/web-common'
import { TheWestSectionMetaDTO } from '@west-australian-newspapers/publication-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { Headline } from '../publication/Headline/Headline'
import { RegionalWALogo } from './components/RegionalWALogo'
import {
    StyledLogoContainer,
    StyledLogoNavLink,
    StyledRegionalItem,
    StyledRegionalLogo,
    StyledRegionList,
    StyledWebLink,
} from './our-publication.styled'

const albanyExtraLogo = require('./components/the-extra-publisher-logo-60px-high.png')

export interface PublicationListItemProps {
    title: string | undefined
    domain: string | undefined
    logo: any
    contact: any
}

export const PublicationListItem: React.FC<PublicationListItemProps> = (
    props,
) => {
    const { title, domain, logo, contact } = props

    return (
        <StyledRegionalItem>
            <RegionalLogoContainer domain={domain}>
                <StyledRegionalLogo
                    src={logo.image || logo}
                    alt={`${title} Logo`}
                />
            </RegionalLogoContainer>
            <div>
                {contact.page && (
                    <StyledWebLink
                        aria-label={`Contact ${title}`}
                        to={contact.page}
                        openExternalInNewWindow={true}
                        rel="noopener"
                    >
                        Contact us
                    </StyledWebLink>
                )}
            </div>
        </StyledRegionalItem>
    )
}

export const RegionalLogoContainer: React.FC<{
    children: React.ReactElement
    domain: string | undefined
}> = ({ children, domain }) =>
    domain ? (
        <StyledLogoNavLink
            to={`https://${domain}`}
            openExternalInNewWindow={true}
            rel="noopener"
        >
            {children}
        </StyledLogoNavLink>
    ) : (
        children
    )

export const OurPublications: React.FC = () => {
    const { sectionMeta } = useSelector<AppState, MetaState>(
        (state) => state.meta,
    )
    const sectionOverrides =
        sectionMeta.sectionOverrides as TheWestSectionMetaDTO
    const sectionOverrideKeys = Object.keys(sectionOverrides) as Array<
        Exclude<TheWestSection, 'default'>
    >

    return (
        <React.Fragment>
            <StyledLogoContainer>
                <RegionalWALogo width={274} height={96} />
            </StyledLogoContainer>
            <Headline text="Exclusive news from all of our Western Australian publications" />
            <StyledRegionList>
                {sectionOverrideKeys.map((key) => {
                    if (typeof key !== 'string') {
                        return null
                    }

                    const section = sectionOverrides[key]

                    if (!section) {
                        return undefined
                    }

                    const title = section.Title
                    const logo = section.PublisherLogo
                    const contact = section.contact
                    const domain = section.Hostname

                    if (!title || !logo || !contact) {
                        return undefined
                    }

                    /**
                     * DPO-669:
                     * The Albany Advertiser publishes a free paper called The Extra every Friday, sales reps
                     * request that we include a logo for it alongside our other regional publications.
                     */
                    if (key === 'albany-advertiser') {
                        return (
                            <React.Fragment key="fragment">
                                <PublicationListItem
                                    key={key}
                                    title={title}
                                    domain={domain}
                                    contact={contact}
                                    logo={logo}
                                />

                                <PublicationListItem
                                    key="albany-extra"
                                    title="The Extra"
                                    domain="edition.albanyadvertiser.com.au/alx/Default.aspx"
                                    contact={contact}
                                    logo={albanyExtraLogo}
                                />
                            </React.Fragment>
                        )
                    }

                    return (
                        <PublicationListItem
                            key={key}
                            title={title}
                            domain={domain}
                            contact={contact}
                            logo={logo}
                        />
                    )
                })}
            </StyledRegionList>
        </React.Fragment>
    )
}

export default OurPublications
